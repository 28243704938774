import React from "react"
import Layout from "../components/Layout"
import Card from "../components/Card"

const Claimants = () => {
  const cardWidth = "20%"

  return (
    <Layout>
      <div className="claimants__picture" />
      <section className="claimants__section">
        <h1>Refugee Claimants in Canada</h1>
        <div className="claimants__description">
          <p>
            Canada has signed several international treaties including the 1951
            Refugee Convention and supports the protection of people seeking
            asylum. In Canada asylum seekers are called refugee claimants. Find
            out more about:
          </p>
          <ul className="claimants__list">
            <li>
              How to make a refugee claim and Canada&apos;s policies for refugee
              claimants
            </li>
            <li>Specific resources for refugee claimants</li>
            <li>How churches can support refugee claimants</li>
            <li>
              Where to find shelters specifically for newly arrived refugee
              claimants
            </li>
          </ul>
        </div>
        <div>
          <Card
            id="making_a_refugee_claim"
            text="Making a Refugee Claim"
            to="/claimants/claim/"
            width={cardWidth}
          />
          <Card
            id="resources_for_refugee_claimants"
            text="Resources for Refugee Claimants"
            to="/claimants/resources/"
            width={cardWidth}
          />
          <Card
            id="host_church_support"
            text="How Churches Can Support Claimants"
            to="/claimants/support/"
            width={cardWidth}
          />
          <Card
            id="shelters_for_refugee_claimants"
            text="Shelters for Refugee Claimants"
            to="/claimants/shelters/"
            width={cardWidth}
          />
        </div>
      </section>
    </Layout>
  )
}

export default Claimants
